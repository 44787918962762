<template>
  <MyOrdersCard
    :to="`/my-orders/subscription/${latestOrderId}`"
    variant="default"
  >
    <template #header>
      <LotteryLogo v-bind="{ licensedTerritory, lotteryId }" />
    </template>

    <template #header-end>
      <slot name="header" />
    </template>

    <template #list>
      <IconListItemId :id="code" :label="$t('common.words.subscriptionid')" />

      <MyOrdersListItemPlay
        v-bind="{ lotteryId, numberOfPlays }"
        :add-ons="selectedAddOns"
      />

      <MyOrdersListItemParticipating
        v-if="prepaidOptions"
        v-bind="{ prepaidOptions }"
      />

      <IconListItemDate v-bind="{ date }" :label="dateLabel" />
    </template>

    <template #replay-button>
      <MyOrdersReplayButton
        v-if="showReplayButton"
        v-bind="{ lotteryId, playSlipOrderId: latestPlaySlipOrderId }"
        class="py-none px-xl"
        size="small"
        @click.stop.prevent
      />
    </template>

    <template #cta>
      {{ $t('myorders.card.subscription.cta') }}
    </template>
  </MyOrdersCard>
</template>

<script lang="ts" setup>
import { isPast } from 'date-fns'

import {
  type SubscriptionMultipleLines,
  SubscriptionStatus,
} from '~/@types/generated/backend/graphql-schema-types'
import MyOrdersListItemParticipating from '~/features/MyOrders/MyOrdersListItemParticipating.vue'

type MyOrdersSubscriptionCardProps = {
  code: SubscriptionMultipleLines['code']
  date?: Date | string
  latestOrderId: SubscriptionMultipleLines['latestOrderId']
  latestPlaySlipOrderId?: SubscriptionMultipleLines['latestPlaySlipOrderId']
  licensedTerritory: SubscriptionMultipleLines['licensedTerritory']
  lotteryId: SubscriptionMultipleLines['lotteryId']
  nextDate?: Date | string
  numberOfPlays: SubscriptionMultipleLines['numberOfPlays']
  prepaidOptions?: SubscriptionMultipleLines['prepaidOptions']
  selectedAddOns: SubscriptionMultipleLines['selectedAddOns']
  status: SubscriptionMultipleLines['status']
}

const { t } = useI18n()

const props = defineProps<MyOrdersSubscriptionCardProps>()

const activeLicensedTerritory = useLicensedTerritory({ raw: true })

const showReplayButton = computed(() => {
  const isSameLicensedTerritory =
    activeLicensedTerritory === props.licensedTerritory

  const isSubscriptionCancelled = props.status === SubscriptionStatus.Cancelled
  const isSubscriptionInPast = props.nextDate
    ? isPast(new Date(props.nextDate))
    : false

  return (
    props.latestPlaySlipOrderId &&
    isSameLicensedTerritory &&
    isSubscriptionCancelled &&
    isSubscriptionInPast
  )
})

const dateLabel = computed(() =>
  props.status === SubscriptionStatus.Active
    ? t('myorders.detail.playslip.nextdate.label')
    : t('myorders.detail.playslip.lastdate.label'),
)
</script>
