<template>
  <MyOrdersTab v-bind="{ fetchActive, fetchPast }" type="subscription">
    <template #no-results>
      <MyOrdersNoResults variant="subscription" />
    </template>

    <template #active="{ result }">
      <li
        v-for="subscription in getSubscriptions(result)"
        :key="subscription.code"
        data-containerid="subscriptions_list_active"
      >
        <MyOrdersSubscriptionCard
          v-bind="subscription"
          :date="subscription.nextDrawDate ?? undefined"
        />
      </li>
    </template>

    <template #past="{ result }">
      <li
        v-for="subscription in getSubscriptions(result)"
        :key="subscription.code"
        data-containerid="subscriptions_list_past"
      >
        <MyOrdersSubscriptionCard
          v-bind="subscription"
          :date="subscription.lastDrawDate"
          :next-date="subscription.nextDrawDate ?? undefined"
        >
          <template #header>
            <MyOrdersStatusBadgeSubscription :status="subscription.status" />
          </template>
        </MyOrdersSubscriptionCard>
      </li>
    </template>
  </MyOrdersTab>
</template>

<script lang="ts" setup>
import {
  type SubscriptionsMultipleLinesQuery,
  SubscriptionStatus,
} from '~/@types/generated/backend/graphql-schema-types'

const ACTIVE_STATUS = [SubscriptionStatus.Active]
const PAST_STATUS = [SubscriptionStatus.Cancelled, SubscriptionStatus.Completed]

const fetchActive = () =>
  useSubscriptionOrders({
    limit: 20,
    status: ACTIVE_STATUS,
  })

const fetchPast = () =>
  useSubscriptionOrders({
    limit: 3,
    status: PAST_STATUS,
  })

const getSubscriptions = (result: SubscriptionsMultipleLinesQuery) =>
  result.subscriptionsMultipleLines.subscriptions
</script>
