import type { OperationVariables } from '@apollo/client'

import type {
  SubscriptionsMultipleLinesQuery,
  SubscriptionStatus,
} from '~/@types/generated/backend/graphql-schema-types'

type Parameters = {
  limit: number
  status: SubscriptionStatus[]
}

type UpdateQueryOptions = {
  fetchMoreResult?: SubscriptionsMultipleLinesQuery
  variables?: OperationVariables
}

const updateQuery = (
  previousResult: SubscriptionsMultipleLinesQuery,
  { fetchMoreResult, variables }: UpdateQueryOptions,
): SubscriptionsMultipleLinesQuery => {
  if (!fetchMoreResult) return previousResult
  const { count, subscriptions } = fetchMoreResult.subscriptionsMultipleLines

  if (
    variables?.limit ===
      previousResult.subscriptionsMultipleLines.subscriptions.length &&
    variables.offset === 0
  ) {
    return fetchMoreResult
  }

  return {
    subscriptionsMultipleLines: {
      ...previousResult.subscriptionsMultipleLines,
      count,
      subscriptions: [
        ...previousResult.subscriptionsMultipleLines.subscriptions,
        ...subscriptions,
      ],
    },
  }
}

export const useSubscriptionOrders = ({ limit, status }: Parameters) =>
  useFetchMoreWrapper(
    () =>
      useSubscriptionsMultipleLinesQuery(
        {
          filter: {
            status,
          },
          limit,
          offset: 0,
        },
        { fetchPolicy: 'cache-first' },
      ),
    updateQuery,
    (result) => result?.subscriptionsMultipleLines.subscriptions.length,
    (result) => result?.subscriptionsMultipleLines.count,
    'subscriptions.error',
  )
